@import '../../Globals.scss';

.devicesTabTitleText {
  margin-bottom: 10px;
}

.devicesActions {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.devicesSyncButton {
  margin-left: 10px;
}

.deviceCard {
  padding: 20px;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
}

.deviceCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deviceCardTitle {
  flex: 1 1 auto;
  font-size: 20px;
  font-weight: 500;
}

.deviceCardBody {
  margin-top: 20px;

  &.hidden {
    display: none;
  }
}

.deviceCardStatus {
  display: flex;
  align-items: center;

  .deviceLevel {
    margin-right: 10px;
    font-size: 16px;
    color: $primaryColor;
  }

  .deviceCardExpandIcon {
    margin-top: 3px;
    margin-left: 10px;
    color: lightgrey;
  }
}
