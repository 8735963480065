@import '../../Globals.scss';

.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 20px;
  font-size: 24px;
  background-color: $primaryColor;
  color: $secondaryColor;
}

.pageHeaderMenuIcon {
  display: flex;
  align-items: center;
  font-size: 30px;
}

.pageHeaderMenuItem {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.pageHeaderMenuItemText {
  margin-left: 10px;
}
