@import '../../Globals.scss';

.scenesTabTitleText {
  margin-bottom: 10px;
}

.scenesFilter {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.sceneCard {
  padding: 20px;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
}

.sceneCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sceneCardTitle {
  flex: 1 1 auto;
  font-size: 20px;
  font-weight: 500;
}

.sceneCardActions {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  color: $primaryColor;
}

.editSceneButton {
  margin-right: 15px;
}

.editSceneButton,
.activateSceneButton {
  font-size: 24px;
}

.sceneCardExpandIcon {
  margin-top: 3px;
  margin-left: 10px;
  color: lightgrey;
}

.sceneCardBody {
  max-height: 200px;
  margin-top: 20px;
  overflow-y: auto;
  border-top: 2px solid $secondaryColor;

  &.hidden {
    display: none;
  }
}

.sceneDevice {
  padding: 5px;
  border-bottom: 2px solid $secondaryColor;
}

.sceneDeviceHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sceneDeviceNameWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.sceneDeviceName {
  margin-top: 3px;
  margin-left: 10px;
  font-size: 16px;
}

.sceneDeviceLevelWrapper {
  display: flex;
  align-items: center;
}

.sceneDeviceLevel {
  margin-right: 10px;
  font-size: 16px;
  color: $primaryColor;
}

.sceneDeviceExpandIcon {
  margin-top: 3px;
  margin-left: 10px;
  color: lightgrey;
  cursor: pointer;
}

.sceneDeviceFooter {
  margin-top: 15px;

  &.hidden {
    display: none;
  }
}

// .ant-tabs-nav-list {
//   flex: none;
// }

.editSceneNameWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.editSceneTitle {
  width: 75px;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.editSceneName {
  margin-bottom: 10px;
}

.editSceneSelectLocationWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.editSceneSelectLocationTitle {
  width: 75px;
  font-size: 16px;
  margin-right: 10px;
}

.editSceneSelectLocation {
  width: 100%;
}

.allDevicesWrapper {
  height: 200px;
  margin-bottom: 20px;
  overflow-y: auto;
}

.allDevicesFilter {
  margin-bottom: 10px;
}

.allDevicesItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 20px;
}

.allDevicesItemLevel {
  font-size: 18px;
  color: $primaryColor;
}

.selectedDevicesWrapper {
  height: 200px;
  margin-bottom: 20px;
  overflow-y: auto;
}

.editSceneDeviceNameWrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.selectedDevicesFilter {
  margin-bottom: 10px;
}

.previewMode {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;

  .previewModeTitle {
    font-size: 14px;
    margin-right: 10px;
  }
}

.editSceneFooterButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .rightButtons,
  .leftButtons {
    display: flex;
    align-items: center;
  }

  .editSceneReset {
    font-size: 16px;
    cursor: pointer;
  }

  .editSceneDelete {
    margin-left: 15px;
    font-size: 16px;
    color: $redColor;
    cursor: pointer;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
  }
}
