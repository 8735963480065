@import '../../Globals.scss';

.locationsTabTitleText {
  margin-bottom: 10px;
}

.locationsFilter {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.newLocationButton {
  margin-left: 10px;
}

.locationCard {
  padding: 20px;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
}

.locationCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.locationCardTitle {
  flex: 1 1 auto;
  font-size: 20px;
  font-weight: 500;
}

.locationCardBody {
  margin-top: 20px;

  &.hidden {
    display: none;
  }
}

.locationCardStatus {
  display: flex;
  align-items: center;

  .editLocationButton {
    color: $primaryColor;
    font-size: 24px;
    cursor: pointer;
  }

  .locationCardExpandIcon {
    margin-top: 3px;
    margin-left: 10px;
    font-size: 20px;
    color: lightgrey;
    cursor: pointer;
  }
}

.locationCardSectionWrapper {
  display: flex;
  margin: 15px 0;
}

.locationCardSectionTitle {
  flex-shrink: 0;
  width: 100px;
  font-size: 16px;
}

.locationCardSectionContent {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.locationOwner {
  font-size: 16px;
}

.locationZipcode {
  font-size: 16px;
}

.locationMember {
  font-size: 16px;
}

.locationCardHubWrapper {
  width: 100%;
}

.editLocationHub {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .editLocationCardHub {
    flex: 1 1 auto;
    background-color: $secondaryColor;
    border-radius: 4px;

    img {
      width: 100%;
    }
  }

  .editLocationCardHubRemoveIcon {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 20px;
    color: $redColor;
  }
}

.locationCardHub {
  margin-bottom: 10px;
  background-color: $secondaryColor;
  border-radius: 4px;

  img {
    width: 100%;
  }
}

.connectToHubModalButton {
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
  text-transform: capitalize;
  background-color: $secondaryColor;
  border-radius: 4px;

  img {
    width: 100%;
  }
}
.editLocationNameWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.editLocationHubsWrapper {
  display: flex;
  margin-bottom: 10px;
}

.editLocationTitle {
  width: 120px;
  font-size: 16px;
  margin-bottom: 10px;
}

.editLocationName {
  margin-bottom: 10px;
}

.editLocationMembersSection {
  display: flex;
  margin-bottom: 10px;
}

.divider {
  height: 2px;
  width: 100%;
  margin: 10px 0;
  background-color: $secondaryColor;
}

.editLocationMembersWrapper {
  width: 100%;

  .editLocationMemberInput {
    display: flex;
    align-items: center;
  }

  .addMemberButton {
    margin-left: 10px;
  }

  .editLocationMember {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  .editLocationMemberName {
    flex: 1 1 auto;
    padding: 5px 10px;
    font-size: 16px;
    background-color: $secondaryColor;
    border-radius: 4px;
  }

  .editLocationMemberRemoveIcon {
    display: flex;
    align-items: center;
    height: 20px;
    width: 20px;
    margin-left: 10px;
    font-size: 20px;
    color: $redColor;
  }
}

.editLocationFooterButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .rightButtons,
  .leftButtons {
    display: flex;
    align-items: center;
  }

  .editLocationReset {
    font-size: 16px;
    cursor: pointer;
  }

  .editLocationDelete {
    margin-left: 15px;
    font-size: 16px;
    color: $redColor;
    cursor: pointer;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
  }
}
