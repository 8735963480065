@import '../../Globals.scss';

.footerTabs {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 0;
  background-color: $secondaryColor;
  // background-color: darken($secondaryColor, 3%);
}

.footerTab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  font-size: 14px;
  cursor: pointer;

  .footerTabIcon {
    font-size: 22px;
  }

  &.selected {
    color: $primaryColor;
    font-weight: bold;
  }
}
