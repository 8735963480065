@import '../Globals.scss';

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 800px;
  overflow: hidden;
}

.tabContents {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;

  .tabTitle {
    padding: 10px 20px 0px;
    font-size: 30px;
  }

  .tabBody {
    flex: 1 1 auto;
    padding: 0 20px;
    overflow-y: auto;
  }
}

.ant-btn-primary {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
}

.ant-btn-dangerous {
  background-color: $redColor !important;
  border-color: $redColor !important;
}
