@import '../../Globals.scss';

.eventsTabTitleText {
  margin-bottom: 10px;
}

.eventsFilter {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.eventCard {
  padding: 20px;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
}

.eventCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eventCardTitle {
  flex: 1 1 auto;
  font-size: 20px;
  font-weight: 500;
  margin-right: 10px;
}

.eventCardActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  cursor: pointer;
  color: $primaryColor;
}

.editEventButton {
  margin-top: 5px;
  font-size: 24px;
}

.eventCardEnabledSwitch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.eventCardEnabledSwitchTitle {
  font-size: 12px;
  margin-right: 10px;
  color: black;
}

.eventCardBody {
  max-height: 200px;
  margin-top: 10px;
  overflow-y: auto;

  &.hidden {
    display: none;
  }
}

.eventCardExpandIcon {
  margin-top: 3px;
  color: lightgrey;
  font-size: 24px;
}

.eventCardSectionWrapper {
  display: flex;
  margin: 10px 0;
}

.eventCardSectionTitle {
  flex-shrink: 0;
  width: 100px;
  font-size: 16px;
}

.eventCardSectionContent {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.eventCardTag {
  padding: 3px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  background-color: $primaryColor;
  color: $secondaryColor;
}

.eventCardSectionDescription {
  font-size: 16px;
}

.editEventNameWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.editEventTitle {
  width: 120px;
  font-size: 16px;
  margin-bottom: 10px;
}

.editEventName {
  margin-bottom: 10px;
}

.editEventSelectLocationWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.editEventSelectLocationTitle {
  width: 120px;
  font-size: 16px;
}

.editEventSelectLocation {
  width: 100%;
}

.editEventSelectCategories {
  display: flex;
  margin-bottom: 15px;
}

.editEventSelectCategoriesTitle {
  width: 120px;
  margin-top: 3px;
  margin-bottom: 10px;
  font-size: 16px;
}

.editEventFooterButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .rightButtons,
  .leftButtons {
    display: flex;
    align-items: center;
  }

  .editEventReset {
    font-size: 16px;
    cursor: pointer;
  }

  .editEventDelete {
    margin-left: 15px;
    font-size: 16px;
    color: $redColor;
    cursor: pointer;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
  }
}

.editEventTimeWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.editEventTimeTitle {
  width: 70px;
}

.editEventTimeForm {
  display: flex;
  margin-bottom: 10px;
}

.editEventTimeOffsetInput {
  margin: 0 5px;
}
