@import '../../Globals.scss';

.loginWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 36px;
  font-weight: bold;
  color: $secondaryColor;
  background-color: $primaryColor;
}
