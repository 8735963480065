@import '../../Globals.scss';

.scheduleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
  padding-bottom: 0;
}

.scheduleCalendar {
  margin-bottom: 15px;

  .react-calendar__navigation {
    height: 40px;
    margin-bottom: 0;
  }
}

.scheduleTimeAndPlaceForm {
  display: flex;
  align-items: center;
  width: 350px;
}

.scheduleTimePickerContainer {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.scheduleTimePickerTitle {
  margin-right: 10px;
  font-size: 16px;
}

.scheduleTimePicker {
  flex: 1 1 auto;

  .ant-picker {
    width: 100%;
  }
}

.scheduleSelectLocationWrapper {
  display: flex;
  align-items: center;
}

.scheduleSelectLocationTitle {
  margin-right: 10px;
  font-size: 16px;
}

.scheduleSelectLocation {
  flex: 1 1 auto;
}

.scheduleCalculatedSceneWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  padding-top: 5px;
  overflow: hidden;

  .divider {
    height: 1px;
    width: 100%;
    margin: 5px 0;
    background-color: lightgray;
  }
}

.emptyScheduledScene {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
}

.emptyScheduledSceneTimestamp {
  font-weight: bold;
  text-align: center;
}

.scheduleCalculatedScene {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.scheduleCalculatedSceneTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
}

.scheduleCalculatedSceneTitleRight {
  display: flex;
  align-items: center;
}

.scheduleCalculatedSceneDate {
  margin-right: 10px;
  font-weight: bold;
}

.scheduleCalculatedSceneDateIcon {
  font-size: 20px;
  cursor: pointer;
}

.scheduledSceneDevices {
  flex: 1 1 auto;
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;
}

.scheduleDeviceInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  font-size: 16px;
  border-bottom: 1px solid lightgray;
}

.scheduleDeviceInfoLevel {
  color: $primaryColor;
}

.scheduleCalculatedSceneModalWrapper {
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow: hidden;
}

.scheduleCalculatedSceneModalTitle {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.scheduleCalculatedSceneModalContent {
  flex: 1 1 auto;
  overflow-y: auto;
}
